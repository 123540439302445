<template>
  <!-- begin::Accounts -->
  <div class="card card-custom --card-stretch gutter-b">
    <div class="row" v-if="showDataDebug">
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="m-0"><b>Count:</b> {{ Balances.length }} <br/> <b>Balances:</b> {{ Balances }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="m-0"><b>Count:</b> {{ localBalances.length }} <br/> <b>localBalances:</b>   <span class="text-warning">{{ localBalances }}</span></pre>
        </div>
      </div>
    </div>

    <!--begin::Body-->
    <div class="card-body p-0">
      <!-- Grouping selector -->
      <b-button-group size="lg" class="m-0 btn-block">
        <b-button
          @click="setGrouping('PalletID')"
          :pressed="groupBy === 'PalletID' ? true : false"
          class="p-5 rounded-bottom-0 rounded-left-sm text-left font-weight-bolder"
        >
          <i class="fas fa-layer-group mr-1" v-show="groupBy === 'PalletID'"></i>
          <translate>Group by pallet</translate>
        </b-button>
        <b-button
          @click="setGrouping('PartnerID')"
          :pressed="groupBy === 'PartnerID' ? true : false"
          class="p-5 rounded-bottom-0 rounded-right-sm text-left font-weight-bolder"
        >
          <i class="fas fa-layer-group mr-1" v-show="groupBy === 'PartnerID'"></i>
          <translate>Group by partner</translate>
        </b-button>
      </b-button-group>

      <!-- Filters -->
      <v-app class="rounded-top-sm d-none d-sm-block">
        <b-button-group class="m-0 btn-block bg-opalean-gray-light rounded-top-0 rounded-sm">
          <!-- Filter button -->
          <b-button v-b-toggle.list-filters size="lg" block variant="opalean-gray-light" class="p-5 rounded-top-0 rounded-sm text-left font-weight-bold m-0"
            ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
            <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
          </b-button>

          <!-- Actions -->
          <b-dropdown
            squared
            variant="opalean-gray-light"
            class="w-10 --border-left --border-opalean-gray-medium"
            toggle-class="rounded-top-0 btn-hover-bg-opalean-gray-medium btn-hover-text-dark btn-hover-icon-dark"
            no-caret
            right
          >
            <template #button-content>
              <i class="la la-ellipsis-v icon-xl"></i>
            </template>

            <b-dropdown-header id="dropdown-header-1" v-translate>Export</b-dropdown-header>
            <b-dropdown-item>
              <vue-excel-xlsx
                :data="this.excelFilteredItems"
                :columns="columnsExcel"
                :fileName="`${$gettext('Accounts')}_${getNow()}`"
                :sheetName="$gettext('Accounts sheet')"
                class="stretched-link"
              >
                <translate>Excel (*.xls)</translate>
              </vue-excel-xlsx>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <translate>Print (*.pdf)</translate>
              <span class="ml-2 label label-outline-info label-pill label-inline" v-translate>Coming soon</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </b-button-group>
        <b-collapse id="list-filters" class="mt-0" v-model="collapsed">
          <b-card class="border-top-0 rounded-top-0 rounded-sm" body-class="px-5 bg-opalean-whiter">
            <p class="card-text font-size-lg font-weight-bold" v-translate>Filters</p>

            <div class="mt-2 row d-flex">
              <div class="col-12 col-sm-3 mr-2 --mb-2">
                <b-form-group
                  :label="$gettext('Search')"
                  label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                  :description="$gettext('Examples : Auchan, Grimonprez, Grim, 0003, AF, Opalean, Dusseldofer, 11/12...')"
                  label-for="filter-input"
                >
                  <b-input-group size="lg" class="pt-3">
                    <b-form-input id="filter-input" v-model="search" type="search" :placeholder="$gettext('Type to search')"></b-form-input>

                    <b-input-group-append>
                      <b-button @click="search = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>

              <!--  <div class="form-group col-2 ml-5">
                <b-form-group
                  label="Filter on"
                  label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                  description="Leave all unchecked to filter on all data"
                  class="mt-2 row d-flex"
                  buttons
                >
                  <b-form-checkbox
                    v-model="selectVal.PartnerIsLinked.value"
                    size="lg"
                    class="mr-2"
                  >
                    <translate>Linked</translate>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="selectVal.Waiting.value"
                    size="lg"
                    class="mr-2"
                  >
                    <translate>Awaiting</translate>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="selectVal.Litigious.value"
                    size="lg"
                    class="mr-2"
                  >
                    <translate>Claim</translate>
                  </b-form-checkbox>
                </b-form-group>
              </div> -->

              <div class="col-12 col-sm-4">
                <label class="text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Balance range</label>

                <v-range-slider v-model="balanceRange" :max="balanceMax" :min="balanceMin" class="mt-2 d-flex">
                  <template v-slot:prepend>
                    <b-form-input
                      :value="balanceRange[0]"
                      type="number"
                      size="sm"
                      style="width: 80px"
                      class="mt-n1"
                      @change="$set(balanceRange, 0, $event)"
                    ></b-form-input>
                  </template>
                  <template v-slot:append>
                    <b-form-input
                      :value="balanceRange[1]"
                      type="number"
                      size="sm"
                      style="width: 80px"
                      class="mt-n1"
                      @change="$set(balanceRange, 1, $event)"
                    ></b-form-input>
                  </template>
                </v-range-slider>
              </div>

              <FormFields v-for="key in fieldKeys" :key="key" :selectVal="selectVal" :fieldKey="key" :options="listOptions[key]"></FormFields>

              <b-col lg="12">
                <b-button
                  block
                  variant="outline-opalean-gray-medium"
                  size="lg"
                  class="btn-hover-danger font-weight-bold"
                  v-if="!haveAnyFilters()"
                  :disabled="haveAnyFilters()"
                  @click="clearAllFilters($event)"
                  ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                >
              </b-col>
            </div>
          </b-card>
        </b-collapse>
      </v-app>

      <!--begin::Table-->
      <v-app class="rounded-bottom-sm">
        <v-data-table
          id="accountTable"
          :headers="!$vuetify.breakpoint.xs ? headers : mobileHeaders"
          :items="localBalances"
          :page.sync="page"
          :items-per-page.sync="perPage"
          :footer-props="{
            'items-per-page-options': perPageChoices,
          }"
          hide-default-footer
          sort-by="PartnerID"
          :group-by="groupBy"
          :search="search"
          class="table table-vertical-center table-responsive-sm bg-opalean-white mb-0"
          :class="this.groupBy"
          ref="tableItems"
          @current-items="onFiltered"
          mobile-breakpoint="576"
        >
          <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
            <td>
              <v-btn @click="filterGroupItems(items, group, isOpen, toggle)" small icon :ref="group" :data-open="isOpen">
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <span class="font-size-h3 font-weight-bolder align-middle" v-if="groupBy === 'PalletID'">{{
                getPallet(group) | getSafeObjectValue("PalletName")
              }}</span>
              <span class="font-size-h3 font-weight-bolder align-middle" v-else>
                <!-- Handle getPartnerByPartnerID skeleton -->
                <b-skeleton-wrapper :loading="typeof getPartnerByPartnerID(group) === 'undefined'">
                  <template #loading>
                    <b-skeleton animation="fade" :width="Math.floor(10 + Math.random() * 10) + '%'"></b-skeleton>
                  </template>
                  <i
                    v-if="typeof getPartnerByPartnerID(group) !== 'undefined' && getPartnerByPartnerID(group).Attributes.includes('isClient')"
                    class="text-opalean-gray-medium icon-md flaticon2-correct"
                  ></i>
                  {{ getPartnerByPartnerID(group) | getSafeObjectValue("Name") }}
                  <span
                    v-if="typeof getPartnerByPartnerID(group) !== 'undefined' && typeof getPartnerByPartnerID(group).City !== 'undefined'"
                    class="text-muted"
                    >{{ getPartnerByPartnerID(group) | getSafeObjectValue("City") }}</span
                  >
                </b-skeleton-wrapper>
              </span>
            </td>
            <td class="text-end">
              <span
                v-if="items.length > 0 && isOpen === true"
                class="font-size-h4"
                :class="items.length > 0 && items.map((i) => i.Total).reduce((a, b) => a + b) > 0 ? 'text-opalean-info' : 'text-info'"
                ><span class="font-weight-bold --text-dark-50 opacity-75">{{ items.map((i) => i.Total).reduce((a, b) => a + b) | getNumberFormat }}</span>
                &nbsp;<translate>/</translate>&nbsp;<span class="font-weight-bolder --text-dark-75">{{
                  unfilteredItems
                    .filter((b) => b[groupBy] === group)
                    .map((i) => i.Total)
                    .reduce((a, b) => a + b) | getNumberFormat
                }}</span></span
              >
              <span v-else class="font-size-h4 text-muted"
                >—&nbsp;<translate>/</translate>&nbsp;<span class="font-weight-bolder --text-dark-75">{{
                  unfilteredItems
                    .filter((b) => b[groupBy] === group)
                    .map((i) => i.Total)
                    .reduce((a, b) => a + b) | getNumberFormat
                }}</span></span
              >
            </td>
            <td :colspan="headers.length - 2" class="text-end"></td>
          </template>

          <template v-slot:[`item.PartnerID`]="{ item }">
            <!-- Handle getPartnerByPartnerID skeleton -->
            <b-skeleton-wrapper :loading="typeof getPartnerByPartnerID(item.PartnerID) === 'undefined'">
              <template #loading>
                <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
              </template>
              <div class="d-flex justify-content-start py-1 hoverable clickable" @click="clickCell(item, 'Name')">
                <span
                  v-for="(role, index) in getMainRoleTypes(
                    item.PartnerID !== -1
                      ? typeof getPartnerByPartnerID(item.PartnerID) !== 'undefined'
                        ? getPartnerByPartnerID(item.PartnerID).MainRole
                        : null
                      : null
                  )"
                  :key="index"
                  class="symbol symbol-40 mr-3"
                  :class="[`symbol-light-${role.class}`]"
                  :title="showPopovers ? $gettext('Partner role') : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <span class="symbol-label font-size-h5 font-weight-bolder">{{
                    $mainFunctions.getFirstLetters(
                      typeof getPartnerByPartnerID(item.PartnerID) !== "undefined" ? getPartnerByPartnerID(item.PartnerID).Name : "-"
                    )
                  }}</span>
                </span>
                <DetailsPanel
                  v-if="item.PartnerID !== null && item.PartnerID !== 0"
                  :inlineIconSrc="
                    item.PartnerID !== -1 && typeof getPartnerByPartnerID(item.PartnerID) !== 'undefined'
                      ? getPartnerByPartnerID(item.PartnerID).Attributes.includes('isClient') === true
                        ? 'flaticon2-correct'
                        : ''
                      : ''
                  "
                  :title="
                    item.PartnerID !== -1 &&
                    typeof getPartnerByPartnerID(item.PartnerID) !== 'undefined' &&
                    typeof getPartnerByPartnerID(item.PartnerID).Name != 'undefined'
                      ? getPartnerByPartnerID(item.PartnerID).Name
                      : '—'
                  "
                  :titleMuted="
                    typeof getPartnerByPartnerID(item.PartnerID) !== 'undefined' && typeof getPartnerByPartnerID(item.PartnerID).City !== 'undefined'
                      ? getPartnerByPartnerID(item.PartnerID).City
                      : ''
                  "
                  :subtitle="getPallet(item.PalletID) | getSafeObjectValue('PalletName')"
                  :inverted="true"
                >
                </DetailsPanel
                ><!-- getSafeObjectValue('Name') -->
                <span v-else class="text-muted">—</span>
                <v-icon small class="ml-4 mb-2 hoverIcon">mdi-filter-variant</v-icon>
              </div>
            </b-skeleton-wrapper>
          </template>

          <template v-slot:[`item.PalletID`]="{ item }">
            <!-- Handle getPartnerByPartnerID skeleton -->
            <b-skeleton-wrapper :loading="typeof getPartnerByPartnerID(item.PartnerID) === 'undefined'">
              <template #loading>
                <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
              </template>
              <div class="d-flex justify-content-start py-1 hoverable clickable" @click="clickCell(item, 'Name')">
                <span
                  class="symbol symbol-40 mr-3"
                  v-for="(role, index) in getPalletTypes(item.PalletID !== -1 ? getPallet(item.PalletID).PalletType : null)"
                  :key="index"
                  :class="[`symbol-light-${role.class}`]"
                  :title="showPopovers ? $gettext('Pallet type') : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <!--begin::Svg Icon -->
                  <span class="symbol-label font-size-h5 svg-icon svg-icon-md svg-icon-success">
                    <inline-svg :src="role.icon"></inline-svg>
                  </span>
                  <!--end::Svg Icon-->
                </span>

                <DetailsPanel
                  v-if="item.PalletID !== null && item.PalletID !== 0"
                  :title="getPallet(item.PalletID) | getSafeObjectValue('PalletName')"
                  :subtitle="
                    item.PartnerID !== -1 && typeof getPartnerByPartnerID(item.PartnerID) !== 'undefined'
                      ? getPartnerByPartnerID(item.PartnerID).Name +
                        (typeof getPartnerByPartnerID(item.PartnerID).City != 'undefined' ? ' (' + getPartnerByPartnerID(item.PartnerID).City + ')' : '')
                      : '—'
                  "
                  :inverted="true"
                >
                </DetailsPanel
                ><!-- getSafeObjectValue('Name') -->
                <span v-else class="text-muted">—</span>
                <v-icon small class="ml-4 mb-2 hoverIcon">mdi-filter-variant</v-icon>
              </div>
            </b-skeleton-wrapper>
          </template>

          <template v-slot:[`item.Total`]="{ item }">
            <div @click="clickCell(item, 'Total')" class="hoverable clickable">
              <span v-if="item.Total != null" class="font-size-h4" :class="item.Total > 0 ? 'text-opalean-info' : 'text-info'">{{
                item.Total.toLocaleString()
              }}</span>
              <span v-else class="text-muted">—</span>
              <v-icon small class="ml-2 mb-2 hoverIcon">mdi-magnify</v-icon>
            </div>
          </template>

          <template v-slot:[`item.Waiting`]="{ item }">
            <div @click="clickCell(item, 'Waiting')" class="hoverable clickable">
              <span v-if="item.Waiting != null" class="font-size-h4 text-info">{{ item.Waiting.toLocaleString() }}</span>
              <span v-else class="text-muted">—</span>
              <v-icon small class="ml-2 mb-2 hoverIcon">mdi-magnify</v-icon>
            </div>
          </template>

          <template v-slot:[`item.Litigious`]="{ item }">
            <div @click="clickCell(item, 'Litigious')" class="hoverable clickable">
              <span v-if="item.Litigious != null" class="text-danger font-size-h4">{{ item.Litigious.toLocaleString() }}</span>
              <span v-else class="text-muted">—</span>
              <v-icon small class="ml-2 mb-2 hoverIcon">mdi-magnify</v-icon>
            </div>
          </template>

          <template v-slot:[`item._PartnerName`]="{}"></template>
          <template v-slot:[`item._PalletName`]="{}"></template>

          <template v-slot:[`item._PartnerIsLinked`]="{}"> </template>
          <template v-slot:[`item._PartnerDistrict`]="{}"> </template>
          <template v-slot:[`item._PartnerCity`]="{}"> </template>

          <!-- Footer -->
          <template v-slot:footer>
            <v-row class="px-3 py-2 align-center">
              <v-col class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75">
                <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Groups</label>
                <b-button-group>
                  <!-- <b-button @click="toggleAll()" variant="outline-secondary">Toggle Groups</b-button> -->
                  <b-button size="sm" @click="closeAll()" variant="outline-secondary" v-translate>Close all</b-button>
                  <b-button size="sm" @click="openAll()" variant="outline-secondary" v-translate>Open all</b-button>
                </b-button-group>
              </v-col>

              <v-col
                class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                v-if="filteredItemsLength !== itemsLength"
              >
                <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Filtered</label>
                <span class="font-weight-bolder">{{ filteredItemsLength }} <translate>stocks</translate></span>
              </v-col>

              <v-col class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75">
                <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total</label>
                <span class="font-weight-bolder">{{ itemsLength }} <translate>stocks</translate></span>
              </v-col>

              <v-col class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75">
                <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
                <v-select dense outlined hide-details :value="perPage" @change="perPage = parseInt($event, 10)" :items="perPageChoices"> </v-select>
              </v-col>

              <v-col
                class="d-flex flex-column justify-content-center --align-items-center border-right-0 border-bottom-0 border-opalean-gray-light text-dark-75"
              >
                <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
                <v-pagination v-model="page" :length="pageCount" class="pagination-start"></v-pagination>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-app>
    </div>
  </div>

  <!-- end:: Accounts -->
</template>

<style lang="scss">
$data-table-regular-header-height: 1000px;

tr .hoverIcon {
  visibility: hidden;
}
tr:hover .hoverIcon {
  visibility: visible;
}
.hoverable {
}
.clickable {
  cursor: pointer;
}

.PartnerID .PartnerID-hide {
  display: none !important;
}
.PalletID .PalletID-hide {
  display: none !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
var vm = new Vue();

import * as moment from "moment";

import VueExcelXlsx from "vue-excel-xlsx";

import FormFields from "@/view/content/neoopatrace/components/FormFields.vue";
import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

//import ApiService from "@/core/services/api.service";
Vue.use(VueExcelXlsx);

export default {
  title() {
    return vm.$gettext("menu.accounts");
  },
  components: {
    FormFields,
    DetailsPanel,
  },
  name: "accounts",
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      showPopovers: statics.showPopovers,
      // Excel
      columnsExcel: [
        {
          label: vm.$gettext("Partner ID"),
          field: "PartnerID",
        },
        {
          label: vm.$gettext("Partner Name"),
          field: "_PartnerName",
        },
        {
          label: vm.$gettext("Pallet ID"),
          field: "PalletID",
        },

        {
          label: vm.$gettext("Pallet Name"),
          field: "_PalletName",
        },
        {
          label: vm.$gettext("Balance"),
          field: "Total",
        },
        {
          label: vm.$gettext("Awaiting"),
          field: "Waiting",
        },
        {
          label: vm.$gettext("Claim"),
          field: "Litigious",
        },
      ],
      excelFilteredItems: [],
      //searchDistrict: "", // TO REMOVE ?
      // Pagination
      page: 1,
      perPage: 50,
      perPageChoices: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "500", value: 500 },
      ],
      filteredItemsLength: 0,
      // Table group & filters
      groupBy: "PalletID", // Initial grouping selection
      groupSortDescending: false,
      collapsed: false, // Used to collapse filters panel when filter search by item
      search: "",
      filter: null,
      balanceRange: [0, 100], // Initial balance range
      balanceMin: 0,
      balanceMax: 0,
      // Table
      headers: [
        {
          text: vm.$gettext("Partner"),
          value: "PartnerID",
          sortable: false,
          class: "font-size-h4" + (this.groupBy === "PalletID") ? "text-dark" : "text-danger",
        },
        {
          text: vm.$gettext("Pallet"),
          value: "PalletID",
          sortable: false,
          class: "font-size-h4" + (this.groupBy === "PartnerID") ? "text-dark" : "",
        },
        {
          text: vm.$gettext("Partner Name"),
          value: "_PartnerName",
          // width: "0",
          // sortable: false,
          class: "PartnerID-hide",
          cellClass: "PartnerID-hide",
        },
        {
          text: "__PartnerName",
          value: "__PartnerName",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "_PartnerReference",
          value: "_PartnerReference",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        { text: "__PartnerReference", value: "__PartnerReference", width: "0", sortable: false, class: "d-none", cellClass: "d-none" },
        {
          text: vm.$gettext("Pallet Name"),
          value: "_PalletName",
          // width: "0",
          // sortable: false,
          class: "PalletID-hide",
          cellClass: "PalletID-hide",
        },
        {
          text: "_PartnerIsLinked",
          value: "_PartnerIsLinked",
          filter: (value) => {
            if (!this.selectVal._PartnerIsLinked.value) {
              return true;
            } else {
              return value === true;
            }
          },
          sortable: false,
          width: "0",
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "_PartnerDistrict",
          value: "_PartnerDistrict",
          filter: (value) => {
            return value === this.selectVal._PartnerDistrict.value || this.selectVal._PartnerDistrict.value === undefined ? value : undefined;
          },
          sortable: false,
          width: "0",
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "_PartnerCity",
          value: "_PartnerCity",
          filter: (value) => {
            return value === this.selectVal._PartnerCity.value || this.selectVal._PartnerCity.value === undefined ? value : undefined;
          },
          sortable: false,
          width: "0",
          class: "d-none",
          cellClass: "d-none",
        },

        /*{ text: "", value: "", width: "0", sortable: false },*/
        {
          text: vm.$gettext("Balance"),
          value: "Total",
          filter: (value) => {
            //if (this.balanceRange[0] == this.balanceMin && this.balanceRange[1] == this.balanceMax) return true;
            if (value >= this.balanceRange[0] && value <= this.balanceRange[1]) return true;
            else false;
          },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
        {
          text: vm.$gettext("Awaiting"),
          value: "Waiting",
          filter: (value) => {
            return value > 0 || this.selectVal.Waiting.value === false;
          },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
        {
          text: vm.$gettext("Claim"),
          value: "Litigious",
          filter: (value) => {
            return value > 0 || this.selectVal.Litigious.value === false;
          },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
      ],
      mobileHeaders: [
        {
          text: vm.$gettext("Partner"),
          value: "PartnerID",
          class: "font-size-h4" + (this.groupBy === "PalletID") ? "text-dark" : "",
        },
        {
          text: vm.$gettext("Pallet"),
          value: "PalletID",
          class: "font-size-h4" + (this.groupBy === "PartnerID") ? "text-dark" : "",
        },
        {
          text: vm.$gettext("Balance"),
          value: "Total",
          filter: (value) => {
            //if (this.balanceRange[0] == this.balanceMin && this.balanceRange[1] == this.balanceMax) return true;
            if (value >= this.balanceRange[0] && value <= this.balanceRange[1]) return true;
            else false;
          },
          width: "17%", //10%
          class: "font-size-h4",
        },
        {
          text: vm.$gettext("Awaiting"),
          value: "Waiting",
          filter: (value) => {
            return value > 0 || this.selectVal.Waiting.value === false;
          },
          width: "17%", //10%
          class: "font-size-h4",
        },
        {
          text: vm.$gettext("Claim"),
          value: "Litigious",
          filter: (value) => {
            return value > 0 || this.selectVal.Litigious.value === false;
          },
          width: "17%", //10%
          class: "font-size-h4",
        },
      ],
      selectVal: {
        Waiting: {
          value: false,
          type: "checkbox",
          name: vm.$gettext("Awaiting"),
        },
        Litigious: {
          value: false,
          type: "checkbox",
          name: vm.$gettext("Claim"),
        },
        _PartnerIsLinked: {
          value: false,
          type: "checkbox",
          name: vm.$gettext("Linked"),
        },
        _PartnerDistrict: {
          value: undefined,
          type: "select",
          name: vm.$gettext("Partner District"),
        },
        _PartnerCity: {
          value: undefined,
          type: "select",
          name: vm.$gettext("Partner City"),
        },
      },
      //Balances: [], // Moved in computed
      //Pallets: [], // Moved in computed > NO because, this datatable cannot be linked to a computed
      localBalances: [], // LOCAL OBJECT FOR GROUPING
      unfilteredItems: [], // DEV GROUPING
      groupedItems: {
        PartnerIDs: [],
        PalletIDs: [],
      }, // DEV GROUPING
      //MainRoles: statics.partnerMainRoleTypes,
      //PartnerDistrict: [],
      // Parent / child
      hasFormFields: true, // Helper to retrieve $aprent inside the FormFields.vue component
    };
  },
  mixins: [helpers],
  methods: {
    toggleAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] !== undefined && this.$refs[k].$el !== undefined) this.$refs[k].$el.click();
      });
    },
    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        console.log(k);
        console.log(this.$refs[k]);
        if (this.$refs[k] !== undefined && this.$refs[k].$el !== undefined && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    openAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] !== undefined && this.$refs[k].$el !== undefined && !this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    setGrouping(groupBy) {
      // Unfilter localBalances with unfilteredItems
      this.localBalances = this.unfilteredItems;
      // Empty groupedItems selection
      this.groupedItems.PartnerIDs = [];
      this.groupedItems.PalletIDs = [];
      // Open groups if needed
      this.openAll();
      // TO DEBUG > Remettre les $refs.group.header à isOpen true
      // Set group
      this.groupBy = groupBy;
    },
    haveAnyFilters() {
      return (this.search == "" || this.search == undefined) &&
        this.balanceRange[0] == this.balanceMin &&
        this.balanceRange[1] == this.balanceMax &&
        Object.keys(this.selectVal).every((k) => !this.selectVal[k].value)
        ? true
        : false;
    },
    clearAllFilters(e) {
      // Prevent default
      e.preventDefault();
      // Needs to debounce because it's happening right at the same time from unblur
      setTimeout(() => {
        this.search = "";
        this.balanceRange = [this.balanceMin, this.balanceMax];
        Object.keys(this.selectVal).forEach((key) => {
          // Old way
          //this.selectVal[key].value = key !== "_PartnerDistrict" || key !== "_PartnerCity" ? undefined : undefined; // For _PartnerDistrict / _PartnerCity : if false > no data in table

          // New way
          if (typeof this.selectVal[key].value == "boolean" && this.selectVal[key].value === true) this.selectVal[key].value = false;
          if (typeof this.selectVal[key].value == "string" && this.selectVal[key].value != "") this.selectVal[key].value = undefined;

          console.log("clearAllFilters:: finally", this.selectVal[key].value, key);
        });
      }, 100);
    },
    getStatusFromBalance(type) {
      if (type == "Litigious") return "L";
      if (type == "Waiting") return "M";
      if (type == "Total") return undefined;
    },
    // Actions on click
    clickCell(item, type) {
      // Toggle Rows
      if (type == "Name") {
        if (this.groupBy == "PartnerID") {
          this.groupBy = "PalletID";
          this.search = item._PalletName; //ID is not Working
        } else {
          this.groupBy = "PartnerID";
          this.search = item._PartnerName; //ID is not Working
        }
        // Collapse filters
        // Used to collapse filters panel when filter search by item
        if (this.collapsed == false) this.$root.$emit("bv::toggle::collapse", "list-filters");
      } else
        this.$router.push({
          name: "route.operations.list",
          params: {
            PartnerID: item.PartnerID,
            //PartnerID: item._PartnerName, //TEMP: a changer une fois le JSON operation fini
            Status: this.getStatusFromBalance(type),
            runCallBySearch: true, // Run a search call instead a filter
          },
        });
    },
    // Grouping
    filterGroupItems(groupItems, group, isOpen, toggle) {
      console.log("filterGroupItems:: Grouped by : ", this.groupBy);
      console.log("filterGroupItems:: GroupName is : ", group);
      console.log("filterGroupItems:: Group isOpen ? ", isOpen);
      console.log("filterGroupItems:: groupedItems", this.groupedItems);
      console.log("filterGroupItems:: groupItems", groupItems);
      console.log("filterGroupItems:: totalItems::localBalances", this.localBalances);
      console.log("filterGroupItems:: unfilteredItems", this.unfilteredItems);
      console.log("filterGroupItems:: ", groupItems[0].PartnerID);
      console.log("filterGroupItems:: ", groupItems[0].PalletID);

      if (isOpen == false) {
        // isOpen == false > remove the requested ID and back to normal
        // And, Conditionned by group
        if (this.groupBy === "PalletID") {
          this.groupedItems.PalletIDs = this.groupedItems.PalletIDs.filter((i) => groupItems[0].PalletID !== i);
          // Filter to remove the fake item from localBalances
          this.localBalances = this.localBalances.filter((f) => groupItems[0].PalletID === f.PalletID && f.PartnerID !== -1);
          console.log(
            "PalletID = Remove check -1 : ",
            this.localBalances.filter((f) => groupItems[0].PalletID === f.PalletID && f.PartnerID !== -1)
          );
        } else if (this.groupBy === "PartnerID") {
          this.groupedItems.PartnerIDs = this.groupedItems.PartnerIDs.filter((i) => groupItems[0].PartnerID !== i);
          // Filter to remove the fake item from localBalances
          this.localBalances = this.localBalances.filter((f) => groupItems[0].PartnerID === f.PartnerID && f.PalletID !== -1);
          console.log(
            "PartnerID = Remove check -1 : ",
            this.localBalances.filter((f) => groupItems[0].PartnerID === f.PartnerID && f.PalletID !== -1)
          );
        }
      } else {
        // isOpen == true > Adds the requested ID and empty group
        // And, Conditionned by group
        if (this.groupBy === "PalletID") {
          this.groupedItems.PalletIDs.push(groupItems[0].PalletID);
        } else if (this.groupBy === "PartnerID") {
          this.groupedItems.PartnerIDs.push(groupItems[0].PartnerID);
        }
      }

      console.log("filterGroupItems:: then groupedItems", this.groupedItems, this.filter);

      // Then, filter localBalances to exclude requested localBalances and include fake localBalances
      if (this.groupBy === "PalletID") {
        // Filtering from raw unfilteredItems
        // >>>>>>> ATTENTION NE FONCTIONNE PLUS DEPUIS les SETTER GETTER
        this.localBalances = this.unfilteredItems.filter((f) => this.groupedItems.PalletIDs.every((item) => item !== f.PalletID)); // TO DEBUG.. => || (this.groupedItems.PalletIDs.every(item => item === f.PalletID) && f.PartnerID === -1)
        // Add a fake item to keep group visible
        this.groupedItems.PalletIDs.forEach(function (_PalletID) {
          console.log(
            "filterGroupItems::",
            "call" + _PalletID,
            this.localBalances.filter((f) => _PalletID === f.PalletID)
          );
          this.localBalances.unshift(
            {
              PalletID: _PalletID, // group //groupItems[0].PalletID,
              PartnerID: -1,
              PartnerName: "—",
              //
              // Used to keep showing group when close + search
              _PalletName: groupItems[0]._PalletName !== "" && groupItems[0]._PalletName !== "-" ? groupItems[0]._PalletName : "—",
              _PartnerName: groupItems[0]._PartnerName !== "" && groupItems[0]._PartnerName !== "-" ? groupItems[0]._PartnerName : "—",
              __PartnerName: groupItems[0].__PartnerName !== "" && groupItems[0].__PartnerName !== "-" ? groupItems[0].__PartnerName : "—",
              _PartnerReference: groupItems[0]._PartnerReference !== "" && groupItems[0]._PartnerReference !== "-" ? groupItems[0]._PartnerReference : "—",
              __PartnerReference: groupItems[0].__PartnerReference !== "" && groupItems[0].__PartnerReference !== "-" ? groupItems[0].__PartnerReference : "—",
              _PartnerDistrict: groupItems[0]._PartnerDistrict !== "" && groupItems[0]._PartnerDistrict !== "-" ? groupItems[0]._PartnerDistrict : "—",
              _PartnerCity: groupItems[0]._PartnerCity !== "" && groupItems[0]._PartnerCity !== "-" ? groupItems[0]._PartnerCity : "—",
              //
              _PartnerIsLinked: false,
            },
            {
              PalletID: _PalletID, // group //groupItems[0].PalletID,
              PartnerID: -1,
              PartnerName: "—",
              //
              // Used to keep showing group when close + search
              _PalletName: groupItems[0]._PalletName !== "" && groupItems[0]._PalletName !== "-" ? groupItems[0]._PalletName : "—",
              _PartnerName: groupItems[0]._PartnerName !== "" && groupItems[0]._PartnerName !== "-" ? groupItems[0]._PartnerName : "—",
              __PartnerName: groupItems[0].__PartnerName !== "" && groupItems[0].__PartnerName !== "-" ? groupItems[0].__PartnerName : "—",
              _PartnerReference: groupItems[0]._PartnerReference !== "" && groupItems[0]._PartnerReference !== "-" ? groupItems[0]._PartnerReference : "—",
              __PartnerReference: groupItems[0].__PartnerReference !== "" && groupItems[0].__PartnerReference !== "-" ? groupItems[0].__PartnerReference : "—",
              _PartnerDistrict: groupItems[0]._PartnerDistrict !== "" && groupItems[0]._PartnerDistrict !== "-" ? groupItems[0]._PartnerDistrict : "—",
              _PartnerCity: groupItems[0]._PartnerCity !== "" && groupItems[0]._PartnerCity !== "-" ? groupItems[0]._PartnerCity : "—",
              //
              _PartnerIsLinked: true,
              Waiting: 1,
              Litigious: 1,
              Total: this.balanceRange[0] + 1,
            }
          );
        }, this);
        console.log(
          "Check -1 : ",
          this.localBalances.filter((f) => this.groupedItems.PalletIDs.every((item) => item === f.PalletID && f.PartnerID === -1))
        );
      } else if (this.groupBy === "PartnerID") {
        // Filtering from raw unfilteredItems
        this.localBalances = this.unfilteredItems.filter((f) => this.groupedItems.PartnerIDs.every((item) => item !== f.PartnerID));
        // Add a fake item to keep group visible
        this.groupedItems.PartnerIDs.forEach(function (_PartnerID) {
          console.log("call" + _PartnerID);
          this.localBalances.unshift({
            PartnerID: _PartnerID, // group //groupItems[0].PartnerID,
            PalletID: -1,
            PalletName: "—",
            //
            // Used to keep showing group when close + search
            _PalletName: groupItems[0]._PalletName !== "" && groupItems[0]._PalletName !== "-" ? groupItems[0]._PalletName : "—",
            _PartnerName: groupItems[0]._PartnerName !== "" && groupItems[0]._PartnerName !== "-" ? groupItems[0]._PartnerName : "—",
            __PartnerName: groupItems[0].__PartnerName !== "" && groupItems[0].__PartnerName !== "-" ? groupItems[0].__PartnerName : "—",
            _PartnerReference: groupItems[0]._PartnerReference !== "" && groupItems[0]._PartnerReference !== "-" ? groupItems[0]._PartnerReference : "—",
            __PartnerReference: groupItems[0].__PartnerReference !== "" && groupItems[0].__PartnerReference !== "-" ? groupItems[0].__PartnerReference : "—",
            _PartnerDistrict: groupItems[0]._PartnerDistrict !== "" && groupItems[0]._PartnerDistrict !== "-" ? groupItems[0]._PartnerDistrict : "—",
            _PartnerCity: groupItems[0]._PartnerCity !== "" && groupItems[0]._PartnerCity !== "-" ? groupItems[0]._PartnerCity : "—",
            //
            _PartnerIsLinked: true,
            Waiting: 1,
            Litigious: 1,
            Total: this.balanceRange[0] + 1,
          });
        }, this);
        console.log(
          "Check -1 : ",
          this.localBalances.filter((f) => this.groupedItems.PartnerIDs.every((item) => item === f.PartnerID && f.PalletID === -1))
        );
      }

      // Control
      console.log(
        "Filter unfilteredItems Grouped by PalletIDs",
        this.unfilteredItems.filter((f) => this.groupedItems.PalletIDs.some((item) => item === f.PalletID))
      );
      console.log(
        "Filter unfilteredItems Grouped by PartnerIDs",
        this.unfilteredItems.filter((f) => this.groupedItems.PartnerIDs.some((item) => item === f.PartnerID))
      );
      console.log("filteredItems (localBalances)", this.localBalances);

      // Fire the group toggle function
      toggle();
    },
    // Filtering
    // Filtering
    onFiltered(filteredItems) {
      console.log(
        "onFiltered::",
        filteredItems,
        this.haveAnyFilters(),
        typeof this.$refs.tableItems !== "undefined" ? this.$refs.tableItems.$children[0].filteredItems.length : undefined
      );
      // Store updated items length
      // Only if we look for a filter ( not perPage filter )
      if (!this.haveAnyFilters())
        this.filteredItemsLength = this.$refs.tableItems !== undefined ? this.$refs.tableItems.$children[0].filteredItems.length : filteredItems.length;
      else this.filteredItemsLength = this.itemsLength;

      // Then update excel filters
      this.setExcelFilter(filteredItems);
    },
    setExcelFilter() {
      if (this.$refs.tableItems !== undefined) this.excelFilteredItems = this.$refs.tableItems.$children[0].filteredItems;
      else this.excelFilteredItems = this.Balances;
    },
    getNow() {
      return moment().format("DDMMYYYY_h-mm");
    },
    // Process Call
    processCall() {
      console.log("processCall Balances length=" + this.Balances.length);
      // Balance calculation
      let _Totals = this.Balances.map((item) => item.Total);
      this.balanceMin = this.balanceRange[0] = Math.min.apply(null, _Totals);
      this.balanceMax = this.balanceRange[1] = Math.max.apply(null, _Totals);
      // Add labels for filters ( NDW : see it's not to busy to load )
      this.localBalances = this.Balances;
      this.localBalances.forEach((b) => {
        //console.log(typeof this.getPartnerByPartnerID(b.PartnerID));
        // Partners & Pallets
        if (b.PartnerID !== undefined) {
          if (b.PartnerID == 0 && b.PartnerFullName != "") b._PartnerName = b.PartnerFullName;
          else if (b.PartnerID != 0 && typeof this.getPartnerByPartnerID(b.PartnerID) !== "undefined") {
            b._PartnerName = this.getPartnerByPartnerID(b.PartnerID).Name;
            b._PartnerIsLinked = this.getPartnerByPartnerID(b.PartnerID).Attributes.includes("isClient");
            b._PartnerDistrict =
              this.getPartnerByPartnerID(b.PartnerID).District != "undefined" && this.getPartnerByPartnerID(b.PartnerID).District != ""
                ? this.getPartnerByPartnerID(b.PartnerID).District
                : "-";
            b._PartnerCity =
              this.getPartnerByPartnerID(b.PartnerID).City != "undefined" && this.getPartnerByPartnerID(b.PartnerID).City != ""
                ? this.getPartnerByPartnerID(b.PartnerID).City
                : "-";
            b._PalletName = this.getPallet(b.PalletID).PalletName;
          } else {
            b._PartnerName = "-";
            b._PartnerIsLinked = "-";
            b._PartnerDistrict = "-";
            b._PartnerCity = "-";
            b._PalletName = "-";
          }
        }
        //console.log(b._PartnerName);
      });
      // Store objects
      this.unfilteredItems = this.localBalances;
    },
  },
  computed: {
    ...mapGetters(["getPallets", "getBalances", "getPartnerByPartnerID", "getPallet"]), //"getPartners",
    // Data
    // // Solution B
    // Partners: function () {
    //   return this.getPartners;
    // },
    Pallets: function () {
      return this.getPallets;
    },
    Balances: function () {
      // Do not edit datas in computed > watch instead
      return this.getBalances;
    },
    // Pagination
    itemsLength() {
      return this.Balances.length ?? 0;
    },
    pageCount() {
      console.log("pageCount::", this.filteredItemsLength, this.itemsLength, Math.ceil(this.filteredItemsLength / this.perPage) ?? 1);
      return Math.ceil(this.filteredItemsLength / this.perPage) ?? 1;
    },
    // Options
    fieldKeys() {
      return Object.keys(this.selectVal);
    },
    listOptions() {
      // This could be simplified if the select was it's own component.
      const listOptions = {};
      this.fieldKeys.forEach((key) => {
        if (this.selectVal[key].type == "select") {
          const vals = this.localBalances.map((item) => item[key]);
          console.log("listOptions::key", vals);
          // Cheap and efficient unique.
          listOptions[key] = Array.from(new Set(vals));
        }
      });
      // Reorder
      listOptions._PartnerDistrict.sort();

      console.log("listOptions::", listOptions);
      return listOptions;
    },
  },
  watch: {
    Balances: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (newBalances) {
        console.log("Watch::Balances", typeof newBalances, newBalances);
        // To fix filter issue if no Stocks loaded yet
        if (typeof newBalances !== "undefined" && newBalances.length > 0) {
          console.log("Watch::Stocks length=" + newBalances.length);

          // Balance calculation
          let _Totals = this.Balances.map((item) => item.Total);
          this.balanceMin = this.balanceRange[0] = Math.min.apply(null, _Totals);
          this.balanceMax = this.balanceRange[1] = Math.max.apply(null, _Totals);

          // Add labels for filters ( NDW : see it's not to busy to load )
          this.localBalances = this.Balances;
          this.localBalances.forEach((b) => {
            console.log(typeof this.getPartnerByPartnerID(b.PartnerID));
            // Partners & Pallets
            if (b.PartnerID !== undefined) {
              if (b.PartnerID == 0 && b.PartnerFullName != "") b._PartnerName = b.PartnerFullName;
              else if (b.PartnerID != 0 && typeof this.getPartnerByPartnerID(b.PartnerID) !== "undefined") {
                b._PartnerName = this.getPartnerByPartnerID(b.PartnerID).Name;
                b._PartnerReference = this.getPartnerByPartnerID(b.PartnerID).Reference;
                b.__PartnerName = _.lowerCase(_.deburr(this.getPartnerByPartnerID(b.PartnerID).Name)); // Deburrs string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks
                b.__PartnerReference = _.lowerCase(_.deburr(this.getPartnerByPartnerID(b.PartnerID).Reference)); // Deburrs string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks
                b._PartnerIsLinked = this.getPartnerByPartnerID(b.PartnerID).Attributes.includes("isClient");
                b._PartnerDistrict =
                  typeof this.getPartnerByPartnerID(b.PartnerID).District != "undefined" && this.getPartnerByPartnerID(b.PartnerID).District != ""
                    ? this.getPartnerByPartnerID(b.PartnerID).District
                    : "-";
                b._PartnerCity =
                  typeof this.getPartnerByPartnerID(b.PartnerID).City != "undefined" && this.getPartnerByPartnerID(b.PartnerID).City != ""
                    ? this.getPartnerByPartnerID(b.PartnerID).City
                    : "-";
                b._PalletName = this.getPallet(b.PalletID).PalletName;
              } else {
                b._PartnerName = "-";
                b._PartnerIsLinked = "-";
                b._PartnerDistrict = "-";
                b._PartnerCity = "-";
                b._PalletName = "-";
              }
            }
            //console.log(b._PartnerName);
          });

          // Store objects
          this.unfilteredItems = this.localBalances;
        }
      },
    },

    // Solution B
    // Partners: {
    //   //deep: true, // Detect nested value changes inside Objects
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   handler: function (newPartners) {
    //     console.log("Watch::Partners length=" + newPartners.length);
    //     //this.processCall();
    //   },
    // },
    // Balances: {
    //   //deep: true, // Detect nested value changes inside Objects
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   handler: function (newBalances) {
    //     console.log("Watch::Balances length=" + newBalances.length);
    //     //this.processCall();
    //   },
    // },
    // Solution A
    // Balances: {
    //   //deep: true, // Detect nested value changes inside Objects
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   handler: function (newBalances) {
    //     console.log("Watch::localBalances length=" + newBalances.length);
    //     // Balance calculation
    //     let _Totals = newBalances.map((item) => item.Total);
    //     this.balanceMin = this.balanceRange[0] = Math.min.apply(null, _Totals);
    //     this.balanceMax = this.balanceRange[1] = Math.max.apply(null, _Totals);
    //     // Add labels for filters ( NDW : see it's not to busy to load )
    //     newBalances.forEach((b) => {
    //       // Partners & Pallets
    //       if (b.PartnerID !== undefined) {
    //         if (b.PartnerID == 0 && b.PartnerFullName != "") b._PartnerName.push(b.PartnerFullName);
    //         else if (b.PartnerID != 0 && typeof this.getPartnerByPartnerID(b.PartnerID) !== "undefined") {
    //           b._PartnerName = this.getPartnerByPartnerID(b.PartnerID).Name;
    //           b._PartnerIsLinked = this.getPartnerByPartnerID(b.PartnerID).Attributes.includes("isClient");
    //           //console.log(typeof this.getPartnerByPartnerID(b.PartnerID).District);
    //           b._PartnerDistrict = typeof this.getPartnerByPartnerID(b.PartnerID).District != "undefined" && this.getPartnerByPartnerID(b.PartnerID).District != "" ? this.getPartnerByPartnerID(b.PartnerID).District : "—";
    //           b._PalletName = this.getPallet(b.PalletID).PalletName;
    //         }
    //       }
    //     });
    //     // Store objects
    //     this.localBalances = newBalances;
    //     this.unfilteredItems = newBalances;
    //   },
    // },
  },
  mounted() {
    console.info("ListAccounts.vue");
    // First run call
    // this.processCall(); // @Wilhem : TODO Use watch: instead a function ? As Stocks
    // Removed > no watcher

    // Set filtered items length
    this.filteredItemsLength = this.Balances.length;
  },
  // https://jerickson.net/created-vs-mounted-in-vue/
  created() {
    // Load Balances
    this.$store.dispatch("loadBalances").then(() => {
      console.log("loadBalances");
    });

    // Passed params
    if (typeof this.$route.params.PartnerName !== "undefined") this.search = this.$route.params.PartnerName;
    if (typeof this.$route.params.PalletName !== "undefined") this.search = this.$route.params.PalletName;
    if (typeof this.$route.params.GroupBy !== "undefined") this.groupBy = this.$route.params.GroupBy;
  },
};
</script>
